import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Link } from 'gatsby';

export const BannerImages = ({locale}) => {
    return (
        <div className="quote-box mt-5 flex flox-col m-auto md:flex-row">
                <div className="flex-row md:flex w-full m:w-1/4 m-2 relative">
                    <Link to={locale.menu.remodeling}>
                        <div className="z-10 absolute rounded-lg w-full h-full bg-banner">
                            <div className="flex flex-col items-stretch">
                                <div className="bottom-3 left-3 absolute">
                                    <span className="flex text-white uppercase text-xl font-semibold ">
                                    {locale.bannerImages.remodeling}
                                    </span>
                                    <div className="flex bg-primary rounded-md w-28 text-xs p-1 mt-1 text-center">
                                        <span className="text-white m-auto">{locale.bannerImages.button.text}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <StaticImage src="../images/obra-ya-remodelacion.jpg" alt="Obra Ya" layout="constrained"
                            width={271}
                            height={135}
                            className="m-auto   h-auto lg:w-full p-1 rounded-lg z-0"
                            imgClassName="h-auto" />
                    </Link>
                </div>
                
                <div className="flex-row md:flex w-full m:w-1/4 mb-2 mx-2 md:m-2 relative">
                    <Link to={`${locale.menu.build}`}>
                        <div className="z-10 absolute rounded-lg w-full h-full bg-banner">
                            <div className="flex flex-col items-stretch">
                                <div className="bottom-3 left-3 absolute">
                                    <span className="flex text-white uppercase text-xl font-semibold ">
                                    {locale.bannerImages.construction}
                                    </span>
                                    <div className="flex bg-primary rounded-md w-28 text-xs p-1 mt-1 text-center">
                                        <span className="text-white m-auto">{locale.bannerImages.button.text}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <StaticImage src="../images/obraya-01.jpg" alt="Obra Ya" layout="constrained"
                            width={271}
                            height={135}
                            className="m-auto  h-auto lg:w-full p-1 rounded-lg z-0"
                            imgClassName="h-auto"/>
                    </Link>
                </div>
                <div className="flex-row md:flex w-full m:w-1/4 m-2 relative">
                    <Link to={`${locale.menu.design}`}>
                        <div className="z-10 absolute rounded-lg w-full h-full bg-banner">
                            <div className="flex flex-col items-stretch">
                                <div className="bottom-3 left-3 absolute">
                                    <span className="flex text-white uppercase text-xl font-semibold ">
                                    {locale.bannerImages.design}
                                    </span>
                                    <div className="flex bg-primary rounded-md w-28 text-xs p-1 mt-1 text-center">
                                        <span className="text-white m-auto">{locale.bannerImages.button.text}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <StaticImage src="../images/obraya-02.jpg" alt="Obra Ya" layout="constrained"
                            width={271}
                            height={135}
                            className="m-auto  h-auto lg:w-full p-1 rounded-lg z-0"
                            imgClassName="h-auto"/>
                    </Link>
                </div>
                <div className="flex-row md:flex w-full m:w-1/4 m-2 relative">
                    <Link to={`${locale.menu.planning}`}>
                        <div className="z-10 absolute rounded-lg w-full h-full bg-banner">
                            <div className="flex flex-col items-stretch">
                                <div className="bottom-3 left-3 absolute">
                                    <span className="flex text-white uppercase text-xl font-semibold ">
                                    {locale.bannerImages.repair}
                                    </span>
                                    <div className="flex bg-primary rounded-md w-28 text-xs p-1 mt-1 text-center">
                                        <span className="text-white m-auto">{locale.bannerImages.button.text}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <StaticImage src="../images/obra-ya-arreglos.jpg" alt="Obra Ya" layout="constrained"
                            width={271}
                            height={135}
                            className="m-auto  h-auto lg:w-full p-1 rounded-lg z-0"
                            imgClassName="h-auto"/>
                    </Link>
                </div>
            </div>
    )
}

import React, { useEffect, useState } from 'react'
import { AyudaBanner } from './AyudaBanner';
import { BannerImages } from './BannerImages';

export const ThankContent = ({locale}) => {

    const [email, setemail] = useState('');

    useEffect(() => {
        const emailaux = localStorage.getItem('email');
        setemail(emailaux);
        return () => {
        }
    }, [])
    
    return (
        <>
            <section className="flex mt-9">
                <div className="flex-auto sm:flex-1"></div>
                <div className="flex sm:flex-1 justify-end sm:justify-start">
                    <div className="flex flex-col bg-primary p-2 md:p-5 rounded-br-xl md:rounded-br-medium  text-white w-7/12 md:w-8/12 xl:w-6/12 sm:ml-2 mr-4">
                        <span className="text-2xl leading-5 md:leading-7 lg:leading-normal md:text-3xl xl:text-4xl font-bold">
                            {locale.thank.banner.title}
                        </span>
                        <span className="hidden md:block md:text-sm xl:text-lg font-bold mt-1">
                            {locale.thank.banner.subtitle}
                        </span>
                    </div>
                </div>
        </section>
        <section className="p-5 md:p-2 font-serif text-secondary">
                <div className="flex flex-col md:flex-row self-center lg:max-w-6xl rounded-lg bg-white m-auto mt-3 md:mt-6 text-center p-3">
                    <div className="flex flex-col p-1 md:p-5">
                        <span className="uppercase text-2xl font-light">
                            {locale.thank.section.title} <br /><br />
                        </span>
                        <span className="md:w-2/3 m-auto font-light text-xs md:text-base">
                            {locale.thank.section.paragraph} <span className='ml-1 font-bold text-primary'>{email}</span> {locale.thank.section.paragraphEnd}
                            <br/><br/>
                        </span>
                    </div>
                </div>
            </section>
            <AyudaBanner locale={locale} />
            <section className="pt-0 p-5 md:p-2 font-serif text-secondary">
            <BannerImages locale={locale}></BannerImages>
            </section>
        </>
    )
}

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const AyudaBanner = ({locale}) => {
    return (
        <div className="hidden md:block self-center lg:max-w-6xl  bg-white text-left  mt-5 m-auto p-2">
             <div id="ayuda" className="rounded-lg w-full  m-auto">
                <div className="flex pt-3">
                    <div className="flex flex-1">
                        <div className="flex w-1/3">
                            <StaticImage src="../images/asesor-contacto.png" alt="Obra Ya" layout="constrained"
                                width={92}
                                height={118}
                                className="m-auto"
                                imgClassName="h-auto"/>
                        </div>
                        <div className="flex flex-col w-2/3 p-3 mt-2">
                            <div className="flex text-primary text-xl uppercase font-bold">
                                {locale.help.text}
                            </div>
                            <div className=" text-sm text-primary font-light mt-1">
                                {locale.help.subtitle}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-1 flex-row">
                        <div className="flex w-2/5 flex-col mt-3 m-auto items-center">
                            <div className="flex text-xs text-primary font-light">
                                {locale.help.social} 
                            </div>
                            <div className="flex flex-row ml-1 mt-3">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Obrayaconstructora">
                                    <StaticImage src="../images/facebook-blue.svg" alt="Obra Ya" layout="fixed"
                                        width={38}
                                        height={38}
                                        className="my-auto mx-1 w-4/5 h-auto "
                                    />
                                </a>
                                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/obraya.com__/'>
                                <StaticImage src="../images/instagram-blue.svg" alt="Obra Ya" layout="fixed"
                                    width={38}
                                    height={38}
                                    className="my-auto mx-1 w-4/5 h-auto "
                                    />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/obra-ya/">
                                    <StaticImage src="../images/linkedin-blue.svg" alt="Obra Ya"    layout="fixed"
                                        width={38}
                                        height={38}
                                        className="my-auto mx-1 w-4/5 h-auto "
                                        />
                                </a>
                            </div>
                        </div>
                        <div className="flex w-3/5">
                            <div className="flex flex-col self-center font-serif text-sm md:w-full">
                                <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=+5715141529&text=Hola"  className="flex mt-4 lg:mt-0" >
                                    <div className="flex flex-row items-center self-center justify-center bg-success w-full md:w-11/12 rounded-md px-4 md:px-1 py-1 text-md font-semibold font-serif m-auto md:m-0">
                                        <div className="self-center md:justify-center md:mx-0">
                                            <StaticImage src="../images/wsp.png" alt="Obra Ya" layout="fixed"
                                                width={36}
                                                height={37}
                                                className="ml-3"
                                                imgClassName=""/>
                                        </div>
                                        <span className="self-center md:text-left pl-2 block md:justify-end md:mx-0 text-center font text-white uppercase">
                                            {locale.help.button.text}
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

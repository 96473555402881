import React from 'react'
import Layout from '../layout/Layout';

import Seo from '../layout/Seo';
import { ThankContent } from '../components/ThankContent';

const ThankYouPage = ({ pageContext }) => {
    
    const { locale } = pageContext;
    function Child({locale}){
        return(<>
        <Seo 
                title="Homepage"
                description="This is the homepage of my website"
                pathname="https:localhost"
            />
            <ThankContent locale={locale} />
        </>)
    }
    return (
        <Layout locale={locale} background="bg-image-remodeling">
             {props => <Child {...props} />}
            
        </Layout>
    )
}

export default ThankYouPage